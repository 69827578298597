import { lazy } from 'react';

export const SellerRoutes = [
  {
    path: 'my-shop/edit',
    component: lazy(() => import('views/my-shop/main')),
  },
  {
    path: 'seller/categories',
    component: lazy(() => import('views/seller-views/categories')),
  },
  {
    path: 'seller/brands',
    component: lazy(() => import('views/seller-views/brands')),
  },
  {
    path: 'seller/products',
    component: lazy(() => import('views/seller-views/products/products')),
  },
  {
    path: 'seller/gift-cards',
    component: lazy(() => import('views/seller-views/gift-cards/gift-cards')),
  },
  {
    path: 'seller/invites',
    component: lazy(() => import('views/seller-views/invites')),
  },
  {
    path: 'seller/discounts',
    component: lazy(() => import('views/seller-views/discounts')),
  },
  {
    path: 'seller/product/add',
    component: lazy(() => import('views/seller-views/products/products-add')),
  },
  {
    path: 'seller/gift-card/add',
    component: lazy(
      () => import('views/seller-views/gift-cards/gift-cards-add'),
    ),
  },
  {
    path: 'seller/product/:uuid',
    component: lazy(() => import('views/seller-views/products/product-edit')),
  },
  {
    path: 'seller/gift-card/:uuid',
    component: lazy(
      () => import('views/seller-views/gift-cards/gift-card-edit'),
    ),
  },
  {
    path: 'seller/product/import',
    component: lazy(() => import('views/seller-views/products/product-import')),
  },
  {
    path: 'seller/delivery/list',
    component: lazy(() => import('views/seller-views/delivery/delivery')),
  },
  {
    path: 'seller/deliveries/add',
    component: lazy(() => import('views/seller-views/delivery/delivery-add')),
  },
  {
    path: 'seller/delivery/:id',
    component: lazy(() => import('views/seller-views/delivery/delivery-add')),
  },
  {
    path: 'seller/delivery/deliveryman',
    component: lazy(() => import('views/seller-views/delivery/deliverymans')),
  },
  {
    path: 'seller/pos-system',
    component: lazy(() => import('views/seller-views/pos-system/pos-system')),
  },
  {
    path: 'seller/orders',
    component: lazy(() => import('views/seller-views/order/order')),
  },
  {
    path: 'seller/order/details/:id',
    component: lazy(() => import('views/seller-views/order/order-details')),
  },
  {
    path: 'seller/shop-users',
    component: lazy(() => import('views/seller-views/user/shop-users')),
  },
  {
    path: 'seller/payouts',
    component: lazy(() => import('views/seller-views/payouts')),
  },
  {
    path: 'seller/subscriptions',
    component: lazy(() => import('views/seller-views/subscriptions')),
  },
  {
    path: 'seller/looks',
    component: lazy(() => import('views/seller-views/looks')),
  },
  {
    path: 'seller/looks/add',
    component: lazy(() => import('views/seller-views/looks/look-add')),
  },
  {
    path: 'seller/looks/:id',
    component: lazy(() => import('views/seller-views/looks/look-edit')),
  },
  {
    path: 'seller/report',
    component: lazy(() => import('views/seller-views/report')),
  },
  {
    path: 'seller/report/overview',
    component: lazy(() => import('views/seller-views/report-overview')),
  },
  {
    path: 'seller/report/products',
    component: lazy(() => import('views/seller-views/report-products')),
  },
  {
    path: 'seller/report/revenue',
    component: lazy(() => import('views/seller-views/report-revenue')),
  },
  {
    path: 'seller/report/orders',
    component: lazy(() => import('views/seller-views/report-orders')),
  },
  {
    path: 'seller/report/variation',
    component: lazy(() => import('views/seller-views/report-variation')),
  },
  {
    path: 'seller/report/categories',
    component: lazy(() => import('views/seller-views/report-categories')),
  },
  {
    path: 'seller/report/stock',
    component: lazy(() => import('views/seller-views/report-stock')),
  },
  {
    path: 'seller/warehouse',
    component: lazy(() => import('views/seller-views/warehouse/warehouse')),
  },
  {
    path: 'seller/warehouse/create',
    component: lazy(
      () => import('views/seller-views/warehouse/warehouse-create'),
    ),
  },
  {
    path: 'seller/warehouse/:id',
    component: lazy(
      () => import('views/seller-views/warehouse/warehouse-detail'),
    ),
  },
  // {
  //   path: 'seller/point-delivery',
  //   component: lazy(() => import('views/seller-views/point-delivery')),
  // },
  // {
  //   path: 'seller/point-delivery/add',
  //   component: lazy(() => import('views/seller-views/point-delivery/create')),
  // },
  // {
  //   path: 'seller/point-delivery/:id',
  //   component: lazy(() => import('views/seller-views/point-delivery/edit')),
  // },
];
