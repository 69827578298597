import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import productService from '../../services/seller/product';

const initialState = {
  loading: false,
  giftCards: [],
  error: '',
  params: {
    page: 1,
    perPage: 10,
  },
  meta: {},
};

export const fetchSellerGiftCards = createAsyncThunk(
  'giftCard/fetchSellerGiftCards',
  (params = {}) =>
    productService.getAll({ ...initialState.params, ...params, gift: 1 }),
);

const giftCardSlice = createSlice({
  name: 'giftCard',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchSellerGiftCards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSellerGiftCards.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.giftCards = payload.data.map((item) => ({
        ...item,
        id: item.id,
        uuid: item.uuid,
        name: item.translation ? item.translation.title : 'no name',
        active: item.active,
        img: item.img,
      }));
      state.meta = payload.meta;
      state.params.page = payload.meta.current_page;
      state.params.perPage = payload.meta.per_page;
      state.error = '';
    });
    builder.addCase(fetchSellerGiftCards.rejected, (state, action) => {
      const { error } = action;
      state.loading = false;
      state.giftCards = [];
      state.error = error.message;
    });
  },
});

export default giftCardSlice.reducer;
